<template>
  <div class="vs-row">
    <vx-card actionable class="cardx" title="Road Map Brouchers">
      <vs-row class="gaps">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <vs-button color="dark" type="border" @click="pdfpopup()"
            >Upload PDF File</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="CPA"
          >CPA</vs-radio
        >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="CMA"
          >CMA</vs-radio
        >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="CFA"
          >CFA</vs-radio
        >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="FRM"
          >FRM</vs-radio
        >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="USP"
          >USP</vs-radio
        >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="CPA-AA"
          >CPA-AA</vs-radio
        >
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="CISA"
          >CISA</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="EA"
          >EA</vs-radio
        >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="CIA"
          >CIA</vs-radio
        > -->
        
        
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIML-BA"
          >IIML-BA</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIML-FA"
          >IIML-FA</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIML-HR"
          >IIML-HR</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIML-PM"
          >IIML-PM</vs-radio
        >  -->
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIML-FT"
          >IIML-FT</vs-radio
        >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIML-PM"
          >IIML-SF</vs-radio
        > 
         <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="XLRI-HR"
          >XLRI-HR</vs-radio
        >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="XLRI-SH"
          >XLRI-SH</vs-radio
        >
      </vs-row>
      <vs-row style="margin-top: 2%">
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="RPO"
          >RPO</vs-radio
        > -->
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIMI-BA"
          >IIMI-BA</vs-radio
        >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="CPA-US"
          >CPA-US</vs-radio
        >
        <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIMI-AA"
          >IIMI-AA</vs-radio
        >
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="AICPA"
          >AICPA</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIML-SF"
          >IIML-SF</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IITR-BF"
          >IITR-BF</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IITR-DB"
          >IITR-DB</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIMK-CX"
          >IIMK-CX</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IITM-FS"
          >IITM-FS</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IITM-AA"
          >IITM-AA</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IITR-CC"
          >IITR-CC</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIMK-FT"
          >IIMK-FT</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIML-AB"
          >IIML-AB</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IIML-SH"
          >IIML-SH</vs-radio
        > -->
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="IITJ-DE"
          >IITJ-DE</vs-radio
        > -->
       
        <!-- <vs-radio
          style="margin-right: 3%"
          color="dark"
          v-model="broucherType"
          vs-value="XLRI-DM"
          >XLRI-DM</vs-radio
        > -->
      </vs-row>
      <vs-row v-if="this.broucherType === 'CPA'" style="margin-top: 1.5%;">
        <iframe
          id="CPA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CPA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'AICPA'" style="margin-top: 1.5%;">
        <iframe
          id="AICPA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/AICPA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'CPA-AA'" style="margin-top: 1.5%;">
        <iframe
          id="CPA-AA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CPA-AA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'CPA-US'" style="margin-top: 1.5%;">
        <iframe
          id="CPA-US"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CPA-US.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <!-- <vs-row v-if="this.broucherType === 'RPO'" style="margin-top: 1.5%;">
        <iframe
          id="RPO"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/RPO.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row> -->
      <vs-row v-if="this.broucherType === 'CISA'" style="margin-top: 1.5%;">
        <iframe
          id="CISA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CISA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'EA'" style="margin-top: 1.5%;">
        <iframe
          id="EA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/EA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'CIA'" style="margin-top: 1.5%;">
        <iframe
          id="CIA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CIA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'CMA'" style="margin-top: 1.5%;">
        <iframe
          id="CMA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CMA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'CFA'" style="margin-top: 1.5%;">
        <iframe
          id="CFA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/CFA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'FRM'" style="margin-top: 1.5%;">
        <iframe
          id="FRM"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/FRM.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'USP'" style="margin-top: 1.5%;">
        <iframe
          id="USP"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/USP.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIML-BA'" style="margin-top: 1.5%;">
        <iframe
          id="IIML-BA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-BA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIML-FA'" style="margin-top: 1.5%;">
        <iframe
          id="IIML-FA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-FA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIML-HR'" style="margin-top: 1.5%;">
        <iframe
          id="IIML-HR"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-HR.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIML-PM'" style="margin-top: 1.5%;">
        <iframe
          id="IIML-PM"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-PM.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIML-SF'" style="margin-top: 1.5%;">
        <iframe
          id="IIML-SF"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-SF.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIML-FT'" style="margin-top: 1.5%;">
        <iframe
          id="IIML-FT"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-FT.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IITR-BF'" style="margin-top: 1.5%;">
        <iframe
          id="IITR-BF"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IITR-BF.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IITR-DB'" style="margin-top: 1.5%;">
        <iframe
          id="IITR-DB"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IITR-DB.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIMK-CX'" style="margin-top: 1.5%;">
        <iframe
          id="IIMK-CX"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIMK-CX.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IITM-FS'" style="margin-top: 1.5%;">
        <iframe
          id="IITM-FS"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IITM-FS.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IITM-AA'" style="margin-top: 1.5%;">
        <iframe
          id="IITM-AA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IITM-AA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IITR-CC'" style="margin-top: 1.5%;">
        <iframe
          id="IITR-CC"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IITR-CC.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIMK-FT'" style="margin-top: 1.5%;">
        <iframe
          id="IIMK-FT"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIMK-FT.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIML-AB'" style="margin-top: 1.5%;">
        <iframe
          id="IIML-AB"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-AB.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIML-SH'" style="margin-top: 1.5%;">
        <iframe
          id="IIML-SH"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIML-SH.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IITJ-DE'" style="margin-top: 1.5%;">
        <iframe
          id="IITJ-DE"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IITJ-DE.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'XLRI-HR'" style="margin-top: 1.5%;">
        <iframe
          id="XLRI-HR"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/XLRI-HR.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'XLRI-SH'" style="margin-top: 1.5%;">
        <iframe
          id="XLRI-SH"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/XLRI-SH.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIMI-BA'" style="margin-top: 1.5%;">
        <iframe
          id="IIMI-BA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIMI-BA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'IIMI-AA'" style="margin-top: 1.5%;">
        <iframe
          id="IIMI-AA"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/IIMI-AA.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <vs-row v-if="this.broucherType === 'XLRI-DM'" style="margin-top: 1.5%;">
        <iframe
          id="XLRI-DM"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/XLRI-DM.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row>
      <!-- <vs-row v-if="this.broucherType === 'TEST-VIEW'" style="margin-top: 1.5%;">
        <iframe
          id="TEST-VIEW"
          src="https://miles-roadmaps.s3.ap-south-1.amazonaws.com/TEST-VIEW.pdf"
          width="100%"
          height="400"
        ></iframe>
      </vs-row> -->
    </vx-card>
    <vs-popup class="holamundo" :title="'Upload Brouchers For '+this.broucherType" :active.sync="open">
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b class="mb-6 d-theme-text-inverse">
            Upload Only PDF File Here.
          </b>
        </vs-col>
      </vs-row>
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <input
            type="file"
            id="pdffile"
            ref="pdffile"
            v-on:change="onPDFChangeFileUpload()"
          />
        </vs-col>
      </vs-row>
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="dark"
            id="upload"
            type="filled"
            @click="PdfUpload()"
            style="margin-top: 3%;"
            >Upload {{ this.broucherType }} Broucher</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
// import DateRangePicker from "vue2-daterange-picker";
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import moment from "moment";
// import calllog from "../components/pagesComponents/callLogComponent.vue";
// import Datepicker from "vuejs-datepicker";
import axios from "axios";
import constants from "../../constants.json";
// import EventBus from "../components/eventbus.js";
// import router from "@/router";
import vSelect from "vue-select";
export default {
  data() {
    return {
      open: false,
      notuploaded: true,
      broucherType: "CPA",
      broucherType_list: [
        "CPA",
        "CPA-AA",
        "CPA-US",
        "AICPA",
        "CISA",
        "EA",
        "CIA",
        "CMA",
        "CFA",
        "FRM",
        "USP",
        "IIML-BA",
        "IIML-FA",
        "IIML-HR",
        "IIML-PM",
        "IIMI-BA",
        "IIMI-AA",
        "XLRI-HR",
        "XLRI-SH",
        // "RPO",
        "IIML-FT",
        "IIML-SF",
        "IITR-BF",
        "IITR-DB",
        "IIMK-CX",
        "IITM-FS",
        "IITM-AA",
        "IITR-CC",
        "IIMK-FT"
      ],
    };
  },
  components: {
    // Datepicker,
    // calllog,
    // DateRangePicker,
    "v-select": vSelect,
  },
  mounted() {

  },
  watch: {},
  methods: {
    pdfpopup() {
      this.open = true;
    },
    PdfUpload() {
      
      this.$vs.loading();
      let formData = new FormData();
      formData.append("course", this.broucherType);
      formData.append("file", this.PDFBoth);
      let url = `${constants.MILES_CM_BACK}uploadCourseRoadmap`;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status === "success") {
            this.$vs.loading.close();
            document.getElementById(this.broucherType).src = response.data.message;
            this.$vs.notify({
              title: response.data.status,
              text: response.data.message,
              color: "success",
            });
          }else if(response.data.status === "error") {
            this.$vs.loading.close();
            this.$vs.notify({
              title: response.data.status,
              text: response.data.message,
              color: "danger",
            });
          }     
          this.open = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    onPDFChangeFileUpload() {
      this.PDFBoth = this.$refs.pdffile.files[0];
      console.log("data", this.PDFBoth);
    },
    successUpload(e) {
      this.uploaded = true;
      console.log("success", e);
    },
    errorUpload(e) {
      console.log("error", e);
    },
    changeUpload(e, files) {
      console.log("change", e);
      this.saveFile = files;
      console.log("result", this.saveFile);
    },
    deleteFile(e) {
      this.saveFile.splice(e, 1);
      console.log("delete file", this.saveFile.splice(e, 1));
    },
  },
};
</script>
<style>
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
</style>
